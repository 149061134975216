var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('TopBanner',{attrs:{"title":"Settlement Agent Portal","src":require('@/assets/images/banners/banner_aboutus.jpg')}}),_c('v-container',{staticClass:"content-container"},[_c('div',{staticClass:"mb-4 text-right"},[_c('v-btn',{attrs:{"color":"pink","outlined":""},on:{"click":_vm.showPdfViewerByPath}},[_vm._v(" Instruction "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-file-question ")])],1)],1),(_vm.downloadDocsModel.errorMessage)?_c('v-alert',{attrs:{"outlined":"","border":"left","type":"error"}},[_vm._v(" "+_vm._s(_vm.downloadDocsModel.errorMessage)+" ")]):_vm._e(),_c('v-card',{attrs:{"color":"lightgrey"}},[_c('v-card-text',{staticClass:"d-flex align-center justify-space-between"},[_c('div',[_vm._v(" File Access Confirmation (Input all required information in 3 fields below) ")]),_c('div',[_c('v-btn',{attrs:{"color":"error","rounded":""},on:{"click":_vm.resetModel}},[_vm._v(" Reset ")])],1)])],1),_c('v-card',{attrs:{"disabled":_vm.step !== 0}},[_c('v-card-text',[_c('v-form',{ref:"verifyLoanInfoForm",on:{"submit":function($event){$event.preventDefault();return _vm.verifyLoanInfo.apply(null, arguments)}}},[_c('v-card',{staticClass:"pa-4",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('NNXXXXXXX'),expression:"'NNXXXXXXX'"}],attrs:{"label":"Loan Number","counter":9,"rules":[
                    (v) => !!v || 'Loan Number is required',
                    (v) =>
                      (v && v.length == 9) ||
                      'Loan Number must consist of 9 digits',
                  ],"dense":"","required":""},model:{value:(_vm.uploadDocsModel.uploadDocsVM.loanNumber),callback:function ($$v) {_vm.$set(_vm.uploadDocsModel.uploadDocsVM, "loanNumber", $$v)},expression:"uploadDocsModel.uploadDocsVM.loanNumber"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('XXXX'),expression:"'XXXX'"}],attrs:{"label":"Last 4-digit SSN","counter":4,"rules":[
                    (v) => !!v || 'This field is required',
                    (v) =>
                      (v && v.length == 4) || 'This must consist of 4 digits',
                  ],"dense":"","required":"","type":_vm.uploadDocsModel.uploadDocsVM.ssN4Visiable
                      ? 'text'
                      : 'password',"append-icon":_vm.uploadDocsModel.uploadDocsVM.ssN4Visiable
                      ? 'mdi-eye'
                      : 'mdi-eye-off'},on:{"click:append":function($event){_vm.uploadDocsModel.uploadDocsVM.ssN4Visiable =
                      !_vm.uploadDocsModel.uploadDocsVM.ssN4Visiable}},model:{value:(_vm.uploadDocsModel.uploadDocsVM.ssN4),callback:function ($$v) {_vm.$set(_vm.uploadDocsModel.uploadDocsVM, "ssN4", $$v)},expression:"uploadDocsModel.uploadDocsVM.ssN4"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('XXXXX'),expression:"'XXXXX'"}],attrs:{"label":"Property zip","counter":5,"rules":[
                    (v) => !!v || 'This field is required',
                    (v) =>
                      (v && v.length == 5) || 'This must consist of 5 digits',
                  ],"dense":"","required":"","type":_vm.uploadDocsModel.uploadDocsVM.zip5Visiable
                      ? 'text'
                      : 'password',"append-icon":_vm.uploadDocsModel.uploadDocsVM.zip5Visiable
                      ? 'mdi-eye'
                      : 'mdi-eye-off'},on:{"click:append":function($event){_vm.uploadDocsModel.uploadDocsVM.zip5Visiable =
                      !_vm.uploadDocsModel.uploadDocsVM.zip5Visiable}},model:{value:(_vm.uploadDocsModel.uploadDocsVM.zip5),callback:function ($$v) {_vm.$set(_vm.uploadDocsModel.uploadDocsVM, "zip5", $$v)},expression:"uploadDocsModel.uploadDocsVM.zip5"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{ref:"submitBtn",attrs:{"type":"submit","color":"secondary","loading":this.stepLoading}},[_vm._v(" Verify ")])],1)],1)],1)],1)],1)],1),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.downloadDocsModel.status === 200)?_c('section',[_c('v-card',{attrs:{"color":"secondary lighten-2"}},[_c('v-card-text',{staticClass:"d-flex align-center justify-space-between"},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Settlement Agent Documents")])])])],1),_c('v-card',[_c('v-card-text',[(
                  _vm.downloadDocsModel.embeddedDocs.some(
                    (x) =>
                      x.documentCategoryCode === 'DOCS' && !x.documentTypeCode
                  )
                )?_c('div',_vm._l((_vm.downloadDocsModel.embeddedDocs.filter(
                    (x) =>
                      x.documentCategoryCode === 'DOCS' && !x.documentTypeCode
                  )),function(doc){return _c('div',{key:`closing-doc-id-${doc.embeddedDocId}`,staticClass:"my-1"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"error","size":"sm"}},[_vm._v(" mdi-file-pdf-outline ")]),_c('a',{staticClass:"black--text",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showPDFViewer(doc)}}},[_vm._v(" "+_vm._s(`${doc.description}.${doc.fileExtension}`)+" ")])],1)}),0):_c('div',{staticClass:"warning--text"},[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" mdi-close-circle ")]),_vm._v(" Document does not exist ")],1)])],1)],1):_vm._e()]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.uploadDocsModel.status === 200)?_c('section',[_c('v-card',{attrs:{"color":"error lighten-2"}},[_c('v-card-text',{staticClass:"d-flex align-center justify-space-between"},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Uploaded Closing Packages")])])])],1),_c('v-card',[(_vm.uploadDocsModel.uploadDocsVM.uploadDate)?_c('v-card-text',[(
                  _vm.downloadDocsModel.embeddedDocs.some(
                    (x) =>
                      x.documentCategoryCode === 'DOCS' &&
                      x.documentTypeCode === 'UploadClosingPkg'
                  )
                )?_c('div',{staticClass:"text-body-1"},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check-circle ")]),_vm._v(" The document was first uploaded on "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("moment")(_vm.uploadDocsModel.uploadDocsVM.uploadDate,"MM/DD/YYYY")))]),_vm._v(". ")],1):_vm._e(),(
                  _vm.downloadDocsModel.embeddedDocs.some(
                    (x) =>
                      x.documentCategoryCode === 'DOCS' &&
                      x.documentTypeCode === 'UploadClosingPkg'
                  )
                )?_c('div',_vm._l((_vm.downloadDocsModel.embeddedDocs.filter(
                    (x) =>
                      x.documentCategoryCode === 'DOCS' &&
                      x.documentTypeCode === 'UploadClosingPkg'
                  )),function(doc){return _c('div',{key:`closing-doc-id-${doc.embeddedDocId}`,staticClass:"d-flex my-1"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"error","size":"sm"}},[_vm._v(" mdi-file-pdf-outline ")]),_c('a',{staticClass:"black--text",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showPDFViewer(doc)}}},[_vm._v(" "+_vm._s(`${doc.description}.${doc.fileExtension}`)+" ")]),_c('span',{staticClass:"ml-auto font-weight-bold"},[_vm._v(_vm._s(_vm._f("moment")(doc.dateCreated,"MM/DD/YYYY h:mm a")))])],1)}),0):_c('div',{staticClass:"warning--text"},[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" mdi-close-circle ")]),_vm._v(" Document does not exist ")],1)]):_vm._e()],1)],1):_vm._e()]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.uploadDocsModel.status === 200)?_c('section',[_c('v-card',{attrs:{"color":"error lighten-2"}},[_c('v-card-text',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Upload Closing Packages")])])],1),_c('v-card',[_c('v-card-text',[_c('v-stepper',{attrs:{"vertical":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-step',{attrs:{"complete":_vm.step > 1,"step":"1","color":"secondary"}},[_vm._v(" ESCROW INFORMATION "),_c('small')]),_c('v-stepper-content',{attrs:{"step":"1"}},[(_vm.step === 1)?_c('v-form',{ref:"validateEscrowInfoForm",on:{"submit":function($event){$event.preventDefault();return _vm.validateEscrowInfo.apply(null, arguments)}}},[_c('v-card',{staticClass:"pa-4",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Escrow Company Name","rules":[_vm.required],"dense":"","required":""},model:{value:(
                              _vm.uploadDocsModel.uploadDocsVM.escrowCompanyName
                            ),callback:function ($$v) {_vm.$set(_vm.uploadDocsModel.uploadDocsVM, "escrowCompanyName", $$v)},expression:"\n                              uploadDocsModel.uploadDocsVM.escrowCompanyName\n                            "}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Escrow Officer Name","rules":[_vm.required],"dense":"","required":""},model:{value:(
                              _vm.uploadDocsModel.uploadDocsVM.escrowofficerName
                            ),callback:function ($$v) {_vm.$set(_vm.uploadDocsModel.uploadDocsVM, "escrowofficerName", $$v)},expression:"\n                              uploadDocsModel.uploadDocsVM.escrowofficerName\n                            "}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Escrow Email","rules":[_vm.required, _vm.email],"dense":"","required":""},model:{value:(
                              _vm.uploadDocsModel.uploadDocsVM.escrowOfficerEmail
                            ),callback:function ($$v) {_vm.$set(_vm.uploadDocsModel.uploadDocsVM, "escrowOfficerEmail", $$v)},expression:"\n                              uploadDocsModel.uploadDocsVM.escrowOfficerEmail\n                            "}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"}],attrs:{"label":"Escrow Phone","rules":[_vm.required, _vm.phone],"dense":"","required":""},model:{value:(
                              _vm.uploadDocsModel.uploadDocsVM.escrowOfficerPhone
                            ),callback:function ($$v) {_vm.$set(_vm.uploadDocsModel.uploadDocsVM, "escrowOfficerPhone", $$v)},expression:"\n                              uploadDocsModel.uploadDocsVM.escrowOfficerPhone\n                            "}})],1)],1)],1),_c('v-btn',{attrs:{"color":"secondary","type":"submit"}},[_vm._v(" Continue ")])],1):_vm._e()],1),_c('v-stepper-step',{attrs:{"complete":_vm.step > 2,"step":"2","color":"secondary"}},[_vm._v(" Settlement Agent Uploads "),_c('small')]),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-form',{ref:"validateUploadDocForm",on:{"submit":function($event){$event.preventDefault();return _vm.validateUploadDoc.apply(null, arguments)}}},[_c('v-card',{staticClass:"pa-4 mb-4",attrs:{"outlined":"","color":"lightgrey","width":"100%"}},[_c('vue-dropzone',{ref:"submitPackageDropzone",attrs:{"id":"submitPackageDropzone","options":_vm.dropzoneOptions},on:{"vdropzone-error-multiple":_vm.vdropzoneErrorMultiple,"vdropzone-success-multiple":_vm.vdropzoneSuccessMultiple,"vdropzone-queue-complete":_vm.vdropzoneQueueComplete}})],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.step--}}},[_vm._v(" Back ")]),_c('v-btn',{attrs:{"color":"secondary","type":"submit"}},[_vm._v(" Continue ")])],1)],1),_c('v-stepper-step',{attrs:{"complete":_vm.step > 3,"step":"3","color":"secondary"}},[_vm._v(" REVIEW "),_c('small',[_vm._v("Please review your request")])]),_c('v-stepper-content',{attrs:{"step":"3"}},[(_vm.step === 3)?_c('v-form',{ref:"submitClosingDocsForm",attrs:{"lazy-validation":""}},[_c('v-card',{staticClass:"pa-4",attrs:{"flat":"","disabled":_vm.stepLoading}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('NNXXXXXXX'),expression:"'NNXXXXXXX'"}],attrs:{"label":"Loan Number","counter":9,"rules":[
                              (v) => !!v || 'Loan Number is required',
                              (v) =>
                                (v && v.length == 9) ||
                                'Loan Number must consist of 9 digits',
                            ],"dense":"","required":""},model:{value:(_vm.uploadDocsModel.uploadDocsVM.loanNumber),callback:function ($$v) {_vm.$set(_vm.uploadDocsModel.uploadDocsVM, "loanNumber", $$v)},expression:"uploadDocsModel.uploadDocsVM.loanNumber"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('XXXX'),expression:"'XXXX'"}],attrs:{"label":"Last 4-digit SSN","counter":4,"rules":[
                              (v) => !!v || 'This field is required',
                              (v) =>
                                (v && v.length == 4) ||
                                'This must consist of 4 digits',
                            ],"dense":"","required":"","type":_vm.uploadDocsModel.uploadDocsVM.ssN4Visiable
                                ? 'text'
                                : 'password',"append-icon":_vm.uploadDocsModel.uploadDocsVM.ssN4Visiable
                                ? 'mdi-eye'
                                : 'mdi-eye-off'},on:{"click:append":function($event){_vm.uploadDocsModel.uploadDocsVM.ssN4Visiable =
                                !_vm.uploadDocsModel.uploadDocsVM.ssN4Visiable}},model:{value:(_vm.uploadDocsModel.uploadDocsVM.ssN4),callback:function ($$v) {_vm.$set(_vm.uploadDocsModel.uploadDocsVM, "ssN4", $$v)},expression:"uploadDocsModel.uploadDocsVM.ssN4"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('XXXXX'),expression:"'XXXXX'"}],attrs:{"label":"Property zip","counter":5,"rules":[
                              (v) => !!v || 'This field is required',
                              (v) =>
                                (v && v.length == 5) ||
                                'This must consist of 5 digits',
                            ],"dense":"","required":"","type":_vm.uploadDocsModel.uploadDocsVM.zip5Visiable
                                ? 'text'
                                : 'password',"append-icon":_vm.uploadDocsModel.uploadDocsVM.zip5Visiable
                                ? 'mdi-eye'
                                : 'mdi-eye-off'},on:{"click:append":function($event){_vm.uploadDocsModel.uploadDocsVM.zip5Visiable =
                                !_vm.uploadDocsModel.uploadDocsVM.zip5Visiable}},model:{value:(_vm.uploadDocsModel.uploadDocsVM.zip5),callback:function ($$v) {_vm.$set(_vm.uploadDocsModel.uploadDocsVM, "zip5", $$v)},expression:"uploadDocsModel.uploadDocsVM.zip5"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Escrow Company Name","rules":[_vm.required],"dense":"","required":""},model:{value:(
                              _vm.uploadDocsModel.uploadDocsVM.escrowCompanyName
                            ),callback:function ($$v) {_vm.$set(_vm.uploadDocsModel.uploadDocsVM, "escrowCompanyName", $$v)},expression:"\n                              uploadDocsModel.uploadDocsVM.escrowCompanyName\n                            "}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Escrow Officer Name","rules":[_vm.required],"dense":"","required":""},model:{value:(
                              _vm.uploadDocsModel.uploadDocsVM.escrowofficerName
                            ),callback:function ($$v) {_vm.$set(_vm.uploadDocsModel.uploadDocsVM, "escrowofficerName", $$v)},expression:"\n                              uploadDocsModel.uploadDocsVM.escrowofficerName\n                            "}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Escrow Email","rules":[_vm.required, _vm.email],"dense":"","required":""},model:{value:(
                              _vm.uploadDocsModel.uploadDocsVM.escrowOfficerEmail
                            ),callback:function ($$v) {_vm.$set(_vm.uploadDocsModel.uploadDocsVM, "escrowOfficerEmail", $$v)},expression:"\n                              uploadDocsModel.uploadDocsVM.escrowOfficerEmail\n                            "}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"}],attrs:{"label":"Escrow Phone","rules":[_vm.required, _vm.phone],"dense":"","required":""},model:{value:(
                              _vm.uploadDocsModel.uploadDocsVM.escrowOfficerPhone
                            ),callback:function ($$v) {_vm.$set(_vm.uploadDocsModel.uploadDocsVM, "escrowOfficerPhone", $$v)},expression:"\n                              uploadDocsModel.uploadDocsVM.escrowOfficerPhone\n                            "}})],1),_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.uploadDocsModel
                              .uploadDocsVM.closingFileDisplayList),function(closingFile,index){return _c('v-chip',{key:closingFile + '' + index,staticClass:"ma-2",attrs:{"color":"error","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-file-document ")]),_vm._v(" "+_vm._s(closingFile)+" ")],1)}),1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.step--}}},[_vm._v(" Back ")]),_c('v-btn',{attrs:{"color":"secondary","type":"button","loading":_vm.stepLoading},on:{"click":_vm.submitClosingDocs}},[_vm._v(" Submit ")])],1)],1)],1)],1):_vm._e()],1),_c('v-stepper-step',{attrs:{"complete":_vm.step > 4,"step":"4","color":"secondary"}},[_vm._v(" Finish ")]),_c('v-stepper-content',{attrs:{"step":"4"}},[_c('v-alert',{attrs:{"dense":"","text":"","type":"success"}},[_vm._v(" 🎉 Uploaded your closing packages successfully. ")])],1)],1)],1)],1)],1):_vm._e()])],1)],1),_c('PdfViewer',{attrs:{"src":_vm.targetPdfPath}}),_c('StatusDialog'),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }